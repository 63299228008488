@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-size: 1.3em;
  font-family: 'Roboto', sans-serif;
}

.container{
  background-color: #E1E2E1;
  height: 100vh;
  width: 100vw;
  color: rgb(239,239,239);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.calculadora{
    background-color: rgb(37,37,37);
    width: 80%;
    height: 80%;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.171);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width: 500px) { 
  .calculadora{
    font-size: 1.2em;
    width: 65%;
    height: 65%;
  }
}

@media (min-width: 700px) and (min-height: 720px){ 
  .calculadora{
    font-size: 1em;
    width: 65%;
    height: 80%;
  }
}

@media (min-width:801px) {
  .calculadora{
    font-size: 1.5em;
    width: 65%;
    height: 80%;
  }

}

@media (min-width:540px) and (min-height: 720px) {
  .calculadora{
    width: 50%;
    height: 80%;
    font-size: 1em;
  }  
}

@media (min-width:1281px) {
  .calculadora{
    width: 20%;
    height: 80%;
    font-size: 1em;
  }  
}

@media (min-width:1024px){
  .calculadora{
    width: 20%;
    height: 80%;
    font-size: 1em;
  }  
}

@media (min-width:1023px) and (min-height: 1355px){
  .calculadora{
    width: 40%;
    height: 70%;
    font-size: 1.3em;
  }
}  

@media (min-width: 1500px) {
  .calculadora{
    width: 30%;
    font-size: 1.2em;
  }
}

@media (min-width: 600px) and (min-height: 1024px) {
  .calculadora{
    height: 60%;
    font-size: 1.2em;
  }
}
.containerDisplay{
    height: 100%;
    padding: 0% 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

p{
    font-weight: bold;
    text-align: right;
    margin-bottom: 5%;
}

.numeros{
    font-size: 0.90em;
    color: rgba(197, 195, 195, 0.705);
}

.resultado{
    overflow: auto;
    font-size: 1.5em;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.botoes{
    display: flex;
    flex-wrap: wrap;
}

.botoes button{
    padding: 4% 0%;
    font-size: 0.90em;
    width: 25%; 
    margin: 3% 0%;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
}

.botoes button:active{
    background-color: rgb(51, 51, 51);
}

.botoes .operador{
    color: rgb(20, 89, 253);
    font-size: 1.2em;
}

.botoes button:first-child{
    color: rgb(165, 40, 40);
    font-size: 1.2em;
}

.botoes .operador:last-child{
    background-color: rgb(20, 89, 253);
    width: 45%;
    color: white;
    border-bottom-right-radius: 8px;
}
