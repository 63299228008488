.containerDisplay{
    height: 100%;
    padding: 0% 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

p{
    font-weight: bold;
    text-align: right;
    margin-bottom: 5%;
}

.numeros{
    font-size: 0.90em;
    color: rgba(197, 195, 195, 0.705);
}

.resultado{
    overflow: auto;
    font-size: 1.5em;
}