@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-size: 1.3em;
  font-family: 'Roboto', sans-serif;
}

.container{
  background-color: #E1E2E1;
  height: 100vh;
  width: 100vw;
  color: rgb(239,239,239);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}